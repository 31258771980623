<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Редактируемые</div>
          <b-form>
            <b-form-group id="input-group-1" label="Код" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="form.code"
                placeholder="Код"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Группа" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.name"
                placeholder="Группа"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { email, required, minLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
    };
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.form.$touch();
      /* if (this.$v.form.$anyError) {
        submitButton[0].classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
        return;
      } */

      Swal.fire({
        title: '',
        text: 'Сохранено!',
        icon: 'success',
        heightAuto: false,
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
