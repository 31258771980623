<template>
  <div class="auto-dealer-table">
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      item-key="name"
      class="elevation-1 row-links"
      hide-default-footer
      :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
    >
      <template #item.oid="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.oid } }"
          title="Редактировать"
          event=""
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click.native.prevent="clickRow(item.oid)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </router-link>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editItem.name }}</h5>
      </template>

      <model-group-form v-model="editItem" ref="modelGroupForm" />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          Сохранить
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { GET_ITEM } from '@/core/services/store/modelgroups.module';
import ModelGroupForm from '../forms/ModelGroupForm.vue';
export default {
  components: { ModelGroupForm },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },
  },

  data() {
    return {
      modalShow: false,
      editItem: null,
      headers: [
        { text: 'Код', value: 'code', sortable: true },
        {
          text: 'Группа',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Действия',
          value: 'oid',
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM, oid);
      this.modalShow = true;
      return false;
    },

    saveForm() {
      this.$refs?.modelGroupForm?.submit();
    },
  },
};
</script>

<style lang="scss"></style>
